


























































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import { Row, Button, Field, NavBar, CellGroup } from "vant";

declare type orderDate = {
  addressName?: string;
  form?: {
    addrDisProvId?: string;
    addrDispCityId?: string;
    addrDispCounId?: string;
    addrDitailAddr?: string;
    addrMobile?: string;
    emailAddress?: string;
    consignee?: string;
    dutyParagraph?: string;
    invoiceAmt?: number;
    invoiceContent?: string;
    invoiceTitle?: string;
    invoiceType?: "ENTERPRISE" | "PERSONAL";
    orderId?: string;
    remarks?: string;
    orderType?: "ORDER_TICKET" | "ORDER_GOOD" | "ORDER_VIDEO";
  };
};

@Component({
  components: {
    Mixin,
    [Row.name]: Row,
    [Button.name]: Button,
    [Field.name]: Field,
    [NavBar.name]: NavBar,
    [CellGroup.name]: CellGroup,
  },
})
export default class Detail extends Mixins(Mixin) {
  invoiceNotice: order.InvoiceConfirmInfo = {};
  orderId = "";
  // orderType: "ORDER_TICKET" | "ORDER_GOOD" | "ORDER_VIDEO" = "ORDER_TICKET";
  orderType = "ORDER_TICKET";
  orderDate: orderDate = {};
  invoiceAmt = "";
  isShow = true;
  created(): void {
    this.orderId = this.$route.query.orderId
      ? String(this.$route.query.orderId)
      : "";

    if (this.$route.query.orderDate) {
      this.isShow = true;
      this.dealDate();
    }

    if (this.orderId) {
      this.isShow = false;
      this.getInvoiceDetail();
    }
  }

  dealDate(): void {
    let str = String(this.$route.query.orderDate);
    this.orderDate = JSON.parse(str);
    if (this.orderDate.form) {
      let form = this.orderDate.form;
      this.invoiceNotice.invoiceTitle = form.invoiceTitle;
      this.invoiceNotice.dutyParagraph = form.dutyParagraph;
      this.invoiceNotice.invoiceContent = form.invoiceContent;
      this.invoiceAmt = form.invoiceAmt
        ? Number(form.invoiceAmt).toFixed(2)
        : "0";
      this.invoiceNotice.remarks = form.remarks;
      this.invoiceNotice.consignee = form.consignee;
      this.invoiceNotice.addrMobile = form.addrMobile;
      this.invoiceNotice.emailAddress = form.emailAddress;
      this.invoiceNotice.addrDitail = this.orderDate.addressName
        ? this.orderDate.addressName.replace(/\//g, "") +
          (form.addrDitailAddr ? form.addrDitailAddr : "")
        : form.addrDitailAddr
        ? form.addrDitailAddr
        : "";
    }
  }

  onClickLeft(): void {
    this.$router.go(-1);
  }

  /*
   * 获取发票详情
   */
  getInvoiceDetail(): void {
    this.$api.orderApi.orderInvoice.getOrderInvoice(
      this.orderId,
      this.orderType as "ORDER_TICKET" | "ORDER_GOOD" | "ORDER_VIDEO",
      ({ data }) => {
        this.invoiceNotice = data;
        this.invoiceAmt = data.invoiceAmt
          ? Number(data.invoiceAmt).toFixed(2)
          : "0";
      }
    );
  }

  submit(): void {
    if (this.orderDate.form) {
      this.$api.orderApi.orderInvoice.submitOrderInvoice(
        this.orderDate.form,
        ({ data }) => {
          this.$toast.success("开具发票成功");
          this.isShow = false;
          this.$router.go(-1);
        }
      );
    }
  }
}
